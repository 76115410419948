<template>
  <div class="contents">
    <div class="new_title">
      <h1>{{ $t("overall-site-control") }}</h1>
    </div>
    <div class="content-wrap">
      <div class="live-vod">
        <div class="live-box" style="display: none;"><!-- 라이브 존재 시 제일 최신으로 신호가 잡힌 영상이 나옴 라이브가 아닌 상태에서는 영상목록에서 선택해야함 -->
          <span><img src="@/assets/images/icon_svg/live-icon.png" alt="icon" />LIVE</span>
          <vod></vod>
        </div>
        <div v-if="getVideoPlayerList(0) != undefined" class="vod-play-on">
          <!--    라이브 & VOD 모두 가능     -->
          <VideoPlayer
              :src="getVideoPlayerList(0).viewSrc"
              :playerId="isLive?getVideoPlayerList(0).id :'isplay'"
              :isVod="!isLive"
              :isAuto="isLive"
              :isShowLiveText="isLive"
              :isControls="!isLive"
              :isShowVolum="false"
              :isShowFullScreen="false"
              :isUser="userType == 'User'"
              :contentsId="getVideoPlayerList(0).vodObj.contentsId"
              @onTimeUpdate="onTimeUpdate" />
        </div>
        <div class="vod-none" v-else ><!-- 라이브 존재 시 제일 최신으로 신호가 잡힌 영상이 나옴 라이브가 아닌 상태에서는 영상목록에서 선택해야함 -->
          <img src="@/assets/images/icon_svg/vod-none-icon.png" alt="icon" />
          <h4>비행 영상을 선택해 주세요.</h4>
        </div>
        <div class="live-info"> <!--live 설명 부분-->
          <div ref ="map_div" class="comparison-map">
            <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" :isLeftButtonPosition="false" @play-marker-lng-lat="playMarkerLngLat" @map-move-center="onMapCenter" />
            <MapButtonComponent
                      ref="buttonMap"/>
            <img src="@/assets/images/map/map_empty_img.png" style = "z-index: 1; position: relative; width: 100%; height: auto; object-fit: fill;" />
          </div>
          <div class="live-vod-info">
            <div class="live-vod-info-title">
              <h2>메인 VIEW</h2>
              <span v-if="isLive" ><img src="@/assets/images/icon_svg/live-icon.png" alt="icon" />LIVE</span>
              <span v-if="isMainView" class="vod-mark" style="background:#828cab;" ><img src="@/assets/images/icon_svg/vod-icon.png" alt="icon" />녹화영상</span>
            </div>
            <div class="live-vod-user-info">
              <div class="live-user-info-row">
                <h4>조종자</h4>
                <h2>{{ getVideoPlayerList(0)&& getVideoPlayerList(0).vodObj.recordUserName ?  `${getVideoPlayerList(0).vodObj.recordUserName}` :' - '  }} </h2>
              </div>
              <div class="live-user-info-row">
                <h4>소속</h4>
                <h2>{{ getVideoPlayerList(0)&& getVideoPlayerList(0).vodObj.recordUserDeptName ?  `${getVideoPlayerList(0).vodObj.recordUserDeptName}` :' - '  }}</h2>
              
              </div>
            </div>
            <div class="live-vod-drone-info">
              <div class="live-drone-radar">
                <div class="drone-radar-img">
                  <div class="radar-img-box">
                    <div class="drone-header-img" :style="changeImageStyle(0,'azimuth')" >
                      <img src="@/assets/images/icon_svg/drone-header.png" alt="icon" class="drone-header" />
                    </div>
                    <div class="camera-radar-img" :style="changeImageStyle(0,'gimbalDirection')" >
                      <img src="@/assets/images/icon_svg/camera-radar.png" alt="icon" class="camera-radar"  />
                    </div>
                  </div>
                  <span class="drone-coordinate-top">N 0°</span>
                  <span class="drone-coordinate-left">E 90°</span>
                  <span class="drone-coordinate-right">W -90°</span>
                  <span class="drone-coordinate-bottom">S 180°</span>
                </div>
              </div>
              <div class="live-drone-value">
                <div class="live-drone-info-row">
                  <h4>고도</h4>
                  <h2><span>{{ getVideoPlayerList(0) && getVideoPlayerList(0).doneData.height ?  `${getVideoPlayerList(0).doneData.height.toFixed(0)}` :' - '   }}</span><span>m</span></h2>
                </div>
                <div class="live-drone-info-row">
                  <h4>거리</h4>
                  <h2><span>{{ getVideoPlayerList(0) && getVideoPlayerList(0).doneData.distance ?  `${getVideoPlayerList(0).doneData.distance.toFixed(0)}` :' - '   }}</span><span>m</span></h2>
                </div> 
                <div class="live-drone-info-row">
                  <h4>수평</h4>                
                  <h2><span>{{ getVideoPlayerList(0) && getVideoPlayerList(0).doneData.hspeed ?  `${getVideoPlayerList(0).doneData.hspeed.toFixed(0)}` :' - '   }}</span><span>m/s</span></h2>
                </div>
                <div class="live-drone-info-row">
                  <h4>수직</h4>
                <h2><span>{{ getVideoPlayerList(0) && getVideoPlayerList(0).doneData.vspeed ?  `${getVideoPlayerList(0).doneData.vspeed.toFixed(0)}` :' - '   }}</span><span>m/s</span></h2>
                </div>
                <div class="live-drone-info-row">
                  <h4>위도</h4>
                  <h2><span>{{ getVideoPlayerList(0)&&getVideoPlayerList(0).lat  ?  `${getVideoPlayerList(0).lat.toFixed(9)}` :' - ' }}</span></h2>
                </div>
                <div class="live-drone-info-row">
                  <h4>경도</h4>
                  <h2><span>{{ getVideoPlayerList(0)&&getVideoPlayerList(0).lng ?  `${getVideoPlayerList(0).lng.toFixed(9)}` :' - '  }}</span></h2>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comparison-view"> <!--vod 3개 부분-->
        <button @click="openModal">비행영상 검색</button>
        <button class="live-search-btn" @click="openLiveModal"><span class="new-mark" v-if="liveList.length>0" ></span>라이브 검색</button>
        <div class="comparison-row" v-for="(index) in 3" :key="'screenVisible_3_'+index" >

          <div class="comparison-vod" v-if="getVideoPlayerList(index) != undefined" >
            <!--     VOD만 가능       -->
            <VideoPlayer
                :src="getVideoPlayerList(index).viewSrc"
                :playerId="'isplay'"
                :isUser="userType == 'User'"
                :isShowLiveText="false"
                :contentsId="getVideoPlayerList(index).vodObj.contentsId"
                @onTimeUpdate="onTimeUpdate"
                />
            <span class="vod-mark"><img src="@/assets/images/icon_svg/vod-icon.png" alt="icon" />녹화영상</span>
          </div>


          <div class="comparison-vod-none" v-else>
            <img src="@/assets/images/icon_svg/vod-none-icon.png" alt="icon" />
            <h4>비행 영상을 선택해 주세요.</h4>
          </div>

          <div class="comparison-vod-info">
            <div class="vod-info-title">
              <h2><span :class="index==1 ? 'blue' : index==2 ? 'orange' : 'purple'"></span>{{index}}번 VIEW</h2>
              <h4>{{getVideoPlayerList(index)&& getVideoPlayerList(index).vodObj.registerDate ? moment(getVideoPlayerList(index).vodObj.registerDate).format("YYYY.MM.DD HH:mm") : ' - ' }}</h4>
            </div>
            <div class="vod-drone-value-info">
              <div class="vod-radar">
                <div class="drone-radar-img">
                  <div class="radar-img-box">
                    <div class="drone-header-img" :style="changeImageStyle(index,'azimuth')" >
                      <img src="@/assets/images/icon_svg/drone-header.png" alt="icon" class="drone-header"  />
                    </div>
                    <div class="camera-radar-img" :id ="'camera-radar-' +index" :style="changeImageStyle(index,'gimbalDirection')">
                      <img src="@/assets/images/icon_svg/camera-radar.png" alt="icon" class="camera-radar" />
                    </div>
                  </div>
                  <span class="drone-coordinate-top">N 0°</span>
                  <span class="drone-coordinate-left">E 90°</span>
                  <span class="drone-coordinate-right">W -90°</span>
                  <span class="drone-coordinate-bottom">S 180°</span>
                </div>
              </div>
              <div class="vod-drone-value">
                <div class="vod-drone-info-row">
                  <h4>고도</h4>
                  <h2><span>{{ getVideoPlayerList(index) && getVideoPlayerList(index).doneData.height ?  `${getVideoPlayerList(index).doneData.height.toFixed(0)}` :' - '   }}</span><span>m</span></h2>
                </div>
                <div class="vod-drone-info-row">
                  <h4>거리</h4>
                  <h2><span>{{ getVideoPlayerList(index) && getVideoPlayerList(index).doneData.distance ?  `${getVideoPlayerList(index).doneData.distance.toFixed(0)}` :' - '   }}</span><span>m</span></h2>
                </div>
                <div class="vod-drone-info-row">
                  <h4>수평</h4>
                  <h2><span>{{ getVideoPlayerList(index) && getVideoPlayerList(index).doneData.hspeed ?  `${getVideoPlayerList(index).doneData.hspeed.toFixed(0)}` :' - '   }}</span><span>m/s</span></h2>
                </div>
                <div class="vod-drone-info-row">
                  <h4>수직</h4>
                <h2><span>{{ getVideoPlayerList(index) && getVideoPlayerList(index).doneData.vspeed ?  `${getVideoPlayerList(index).doneData.vspeed.toFixed(0)}` :' - '   }}</span><span>m/s</span></h2>
                </div>
                <div class="vod-drone-info-row">
                  <h4>위도</h4>
                  <h2><span>{{ getVideoPlayerList(index) && getVideoPlayerList(index).lat  ?  `${getVideoPlayerList(index).lat.toFixed(9)}` :' - ' }}</span></h2>
                </div>
                <div class="vod-drone-info-row">
                  <h4>경도</h4>
                  <h2><span>{{ getVideoPlayerList(index)&& getVideoPlayerList(index).lng ?  `${getVideoPlayerList(index).lng.toFixed(9)}` :' - '  }}</span></h2>
                </div> 
              </div>
            </div>
            <div class="vod-user-info">
              <div class="vod-user-info-row">
                <!-- 해당 부분 점검 -->
                <h4>조종자</h4>
                <h2>{{ getVideoPlayerList(index)&& getVideoPlayerList(index).vodObj.recordUserName ?  `${getVideoPlayerList(index).vodObj.recordUserName}` :' - '  }} </h2>
          
              </div>
              <div class="vod-user-info-row">
                <h4>소속</h4>
                <h2>{{ getVideoPlayerList(index)&& getVideoPlayerList(index).vodObj.recordUserDeptName ?  `${getVideoPlayerList(index).vodObj.recordUserDeptName}` :' - '  }} </h2>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div v-if="isModalOpen" class="vod-modal">
        <div class="vod-modal-content">
          <div class="modal-content-header">
            <h2>비행영상 검색
              <span>선택된 영상 {{this.vodCheckList.length}}개</span>
            </h2>
            <button @click="closeModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" /></button>
          </div>
          <div class="modal-content-body">
            <div class="modal-input">
              <div class="modal-search">
                <span>검색조건</span>
                <select v-model="keywordType">
                  <option value ="all">전체</option>
                  <option value="registerDepartmentName">소속</option>
                  <option value="registerUserName">조종사</option>
                  <option value="title">영상 제목</option>
                </select>
                <input
                  type="text"
                  v-model="keyword"
                  @keydown.enter="search()"
                />
              </div>
              <div class="modal-datepicker">
                <span>등록일</span>
                
                <div class="datepicker-input">
                  <Datepicker
                    v-model="startYmd"
                    :language="ko"
                    :format="customFormatter"
                    placeholder="YYYY-MM-DD"
                    />
                <Datepicker
                    v-model="endYmd"
                    :language="ko"
                    :format="customFormatter"
                    placeholder="YYYY-MM-DD"/>
                  <!-- <input type="date" placeholder="yyyy-mm-dd" style="margin-right:10px;">
                  <input type="date" placeholder="yyyy-mm-dd"> -->
                </div> 
              </div>
              <div class="buttonWrap" >
                <button  @click="search()">
                  {{ $t("btn-searching") }}
                </button>
                <button  @click="reset()" >
                  {{ $t("btn-reset") }}
                </button>
              </div>
            </div>
            <div class="modal-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>순번</th>
                    <th>썸네일</th>
                    <th>영상 제목</th>
                    <th>소속</th>
                    <th>조종사</th>
                    <th>등록일</th>
                    <th>화면선택</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in vodList" :key="'vodList'+index">
                    <td><input type="checkbox" v-model="vodCheckList" :value="{id : item.contentsId, type : 'Vod'}" @change="videoCheckChange($event, item.contentsId)"></td>
                    <td>{{(currentPage*10)+index+1}}</td>
                    <td class="preview-list">
                      <div class="preview-img">
                        <img :src="baseUrl + item.thumbnailUri">
                      </div>
                      <div class="preview-img-big">
                        <img :src="baseUrl + item.thumbnailUri">
                      </div>
                    </td>
                    <td>{{item.title}}</td>
                    <td>{{item.registerDepartmentName}}</td>
                    <td>{{item.registerUserName}}</td>
                    <td>{{moment(item.registerDate).format("YYYY-MM-DD")}}</td>
                    <td  >
                      <select v-bind:disabled="!vodCheckList.filter(e => e.id === item.contentsId).length > 0" @change="videoSelectChange($event, item.contentsId)">
                        
                        <option :value="null" :selected="isVideoSelected(item.contentsId,null)" >-</option>
                        <option :value="0" :selected="isVideoSelected(item.contentsId,'0')" :disabled="isVideoSelectDisabled('0')||isLive">메인 View</option>
                        <option :value="1" :selected="isVideoSelected(item.contentsId,'1')" :disabled="isVideoSelectDisabled('1')">1번 View</option>
                        <option :value="2" :selected="isVideoSelected(item.contentsId,'2')" :disabled="isVideoSelectDisabled('2')">2번 View</option>
                        <option :value="3" :selected="isVideoSelected(item.contentsId,'3')" :disabled="isVideoSelectDisabled('3')">3번 View</option> 
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              small
              layout="prev, pager, next"
              :page-size="size"
              @current-change="handleCurrentChange"
              :total="total"
              :current-page="currentPage + 1"
            >
            </el-pagination>
          </div> 
          <div class="modal-content-footer">
            <button @click="vodSaveClick">확인</button>
            <button @click="closeModal">취소</button>
            <button @click="vodClear">선택 해제</button>
          </div>
        </div>
      </div>
      <div v-if="isLiveModalOpen" class="live-modal">
        <div class="vod-modal-content">
          <div class="modal-content-header">
            <h2>라이브영상 검색</h2>
            <button @click="closeLiveModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" /></button>
          </div>
          <div class="modal-content-body">
            <div class="modal-table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>순번</th>
                    <th>디바이스 타입</th>
                    <th>디바이스 이름</th>
                    <th>소속</th>
                    <th>조종사</th>
                    <th>촬영시작일시</th>
                  </tr>
                </thead>
                <tbody>
                  <tr ref="liveCheckList" v-for="(item, index) in liveList" :key="'liveList'+index">
                    <td ><input type="checkbox" name ="liveCheckBox" v-model="checkLiveList" :value="{id : item.channelId, type : 'Live', playerINdex : '0' }" @change="liveCheckChange($event, item.channelId)"></td>
                    <td>{{(currentPage*index) +index+1}}</td>
                    <td>{{liveList[index].assignedDeviceType}}  </td>
                    <td>{{liveList[index].assignedDeviceName ? liveList[index].assignedDeviceName : ' - '}}</td>
                    <td>{{liveList[index].assignedDepartmentName}}</td>
                    <td>{{liveList[index].assignedUserName}}</td>
                    <td>{{moment(liveList[index].assingedDate).format("YYYY.MM.DD HH:mm")}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-content-footer">
            <button @click="liveSaveClick">확인</button>
            <button @click="closeLiveModal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Map from "@/components/map/Map"; 
import Datepicker from "vuejs-datepicker";
import {mapState} from "vuex";
import {ko} from "vuejs-datepicker/dist/locale";
import VideoPlayer from "@/components/video/videoPlayer";
import {getDefaultMapZoomLevel} from "@/util/common";
import { fetchChannelList, getLiveUrl } from "@/api/channel";
import { fetchVodDetail, fetchVodList,fetchVodThumbnail } from "@/api/contents";
import { fetchVodUrlList } from "@/api/server";
import { fetchVodSrc } from "@/api/file";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {fetchLocation} from "@/api/location";
 import { fetchEvent } from "@/api/event";
import MapLineData from "@/components/map/data/MapLineData";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapDataType from "@/components/map/enum/MapDataType";
import VodList from "@/views/eventController/contents/VodList"
import { baseUrl } from "@/config/env";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

getLiveUrl
fetchEvent
MapLineData
VodList
baseUrl


export default {
  components: { VideoPlayer ,Datepicker,Map, MapButtonComponent }, //Map VodList
  name : "Comparision",
  data() {
    return {
      isModalOpen: false, // 모달의 초기 상태는 닫혀있음
      isLiveModalOpen: false, // 모달의 초기 상태는 닫혀있음
      baseUrl: baseUrl,
    
      //#region util 관련 data
        eventId : null,
        moment: moment,
        currentPage: 0,
        keywordType: "all",
        keyword: "",
        startYmd: null,
        endYmd: null, 
        ko: ko,
        viewSrc : "",
        doneData : {
        distance : null,
        hspeed : null,
        vspeed : null,
        height : null,
        battery : null,
        heartRate : null,
      },
        vodObj: {},
        location: [],
        lat : null,
        lng : null,
        locationSize : null,
        isPlayStatus: false,
        size : 10 ,
        total : 0 , 
      //#endregion
 
      //#region map 관련 data
       loadMap : "kakao",
       eventInfo : null,
      //#endregion
    
      viewChannelList : [],
      //#region live 관련 data
      liveList: [],
      checkLiveList : [],
      liveTotal : 0,
      liveId : null,
      liveCurrentPage : 0 ,
      vodUrl : "",

      
      //#endregion

      //#region vod 관련 data
      vodList : [],
      vodCheckList : [], //checkbox 관리 list 
      //{id: "faecef25-24e3-4ffc-afe9-2c6d3683cb71", type: "Vod"}
      vodSelectList : [], //select관리 list
      // { id: "faecef25-24e3-4ffc-afe9-2c6d3683cb71", type: "Vod", layerIndex : "1",}
      saveVideoPlayer : [], //표출할 vod,live를 관리하는 list {playerIndex : 0,id : "conetentsId",type : "Vod, Live",}
      
      //#endregion
      azimuth : 30,
      markerColorName : ["red", "blue", "orange", "purple"]
    };
  },
  computed : {
    ...mapState({
      currentLang: state => state.store.currentLang,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
    }),

    allowedCheckListLength() {
      console.log("allowedCheckListLength")
      if (this.isLive) {
        return 3; // Live인 경우 3개만 선택 가능
      } else {
        return 4; // Live가 아닌 경우 4개만 선택 가능
      }
    },
    isLengthExceeded() {
      return this.vodCheckList.length > this.allowedCheckListLength;
    },
    isLive(){
      return this.saveVideoPlayer.filter(item =>{
        return item.type == "Live" 
      }).length > 0 
    },
    isMainView(){
      return this.saveVideoPlayer.filter(item =>{
        return (item.playerIndex == '0' && item.type != "Live")
      }).length > 0
    },
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },

  create(){

  },
  async mounted(){
    this.eventId =this.$route.params.eventId
    this.initButtonMap()
    this.getEvent();
    this.initDoneData();
    this.getVodList();
    this.initMapData(this.$store.state.store.currentLang );
    this.getContentsList();

    await this.getLiveList();
    this.positionInterval = setInterval( () => {
      this.updateMapPosition()
    }, 1000);

    this.lineInterval = setInterval( () => {
      this.updateMapLine()
    }, 2000);

    this.interval = setInterval(() => {
      this.getLiveList();
    }, 3000);

  },
  methods: {
    checkDevice(viewChannelData) {
      if(viewChannelData.assignedDeviceType === 'Drone'|| viewChannelData.assignedDeviceType === 'StationDrone'){
        return viewChannelData.streamServer.properties.droneApplication
      }
      return viewChannelData.streamServer.properties.application
    },
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showMapCenter()
    },

  openModal() {
    this.reset()
    this.getContentsList();
    this.isModalOpen = true; // 모달 열기
    
    this.saveVideoPlayer.forEach(item => {
      if(item.type == "Vod") {
        this.vodSelectList.push({id:item.id, playerIndex : item.playerIndex , type:item.type});
        this.vodCheckList.push({id:item.id, type:item.type});
      }
    })
    
    console.log(this.vodList)
  
  },
  closeModal() {
    this.vodSelectList = [];
    this.vodCheckList = [];
    this.isModalOpen = false; // 모달 닫기?
  },
  openLiveModal() {
    this.reset()
    this.saveVideoPlayer.forEach(item =>{
      if(item.type == "Live"){
         this.checkLiveList.push({id : item.id, type : 'Live', playerINdex : '0' });
         this.liveId = item.id;
      }
    })
    this.isLiveModalOpen = true; // 모달 열기
  },
  closeLiveModal() {
    this.checkLiveList = [];
    this.liveId = null;  
    this.isLiveModalOpen = false; // 모달 닫기
  },

   customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
  },

  search() {
    console.log("search")
    console.log("start : ", this.startYmd , "end : " , this.endYmd);
    console.log("keyword : " , this.keyword , "keywordType : ", this.keywordType);
    if (this.startYmd != null) {
      if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
        return alert(this.$t("alert-message-check-regdate"));
      }
    }
    this.currentPage = 0;
    this.getVodList();
  },
  reset(){
      this.currentPage = 0;
      this.keyword = "";
      this.keywordType = "all";
      this.startYmd = null;
      this.endYmd = null;
    },
  initDoneData(){
      this.doneData.distance = null;
      this.doneData.hspeed = null;
      this.doneData.vspeed = null;
      this.doneData.height = null;
      this.doneData.battery = null;
      this.doneData.heartRate = null;
    },
  setDoneData(data){
    var doneData = {
    distance : data.distance ? data.distance : 0 ,
    hspeed : data.hspeed ? data.hspeed : 0 ,
    vspeed : data.vspeed ? data.vspeed : 0 ,
    height : data.height ? data.height : 0 ,
    battery : data.battery ? data.battery : 0 ,
    heartRate : data.heartRate ? data.heartRate : 0 ,
    }
   return doneData
  },
  setVodObj(data){
    var vodObj = {
      recoredUserName : data.assignedUserName ? data.assignedUserName : ' - ',
      recordUserDeptName : data.assignedDepartmentName ? data.assignedDepartmentName: ' - ',
      deviceName : data.assignedDeviceName ? data.assignedDeviceName : '휴대폰',
      registerDate : data.assignedDate ?data.assignedDate : ' - ',
      deviceType : data.assignedDeviceType ? data.assignedDeviceType : ' - ',
      workerId : data.assignedWorkerId ? data.assignedWorkerId : ' - ',
      deviceLogId : data.assignedDeviceLogId ? data.assignedDeviceLogId : ' - ',

    }
    return vodObj
  },
  async getContentsList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        eventId: this.eventId,
        deviceType : "Drone,Mobile"
      };
      if (!this.eventId) {
        delete params.eventId;
      }

      await fetchVodList(params).then((res) => {
        this.total = res.data.data.total;
        this.vodList = res.data.data.content;
        // this.getVodList();
        
      });
    },
  handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getContentsList();
    },
    convert(deg) {
      return ((Number(deg) + 360) % 360);
    },
    changeImageStyle(index, type) {
      var list = this.getVideoPlayerList(index);
      var result = 0;
      var value = type == 'azimuth' ? 50 : 50 
      
      if (list == undefined) {
        return { transform: `translate(-50%, -${value}%) rotate(${result}deg) ` };
      }
      switch (type) {
        case 'azimuth':
          result = list.azimuth ? this.convert(list.azimuth) : this.convert(0);
          break;
        case 'gimbalDirection':
          if(list.gimbalDirection == null || list.gimbalDirection == undefined){
            result = list.azimuth ? this.convert(list.azimuth) : this.convert(0);
          }else{
            result = list.gimbalDirection ? this.convert(list.gimbalDirection)+this.convert(list.azimuth)  : this.convert(list.azimuth);
          }
          
          break;
      }
      return { transform: ` translate(-50%, -${value}%) rotate(${result}deg)` };
    },
  //#region map 관련
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      console.log("eventId", this.eventId);
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          if(this.$refs.map == undefined) {
            return;
          }
        
          if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
          } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
          }
        }
      });
     
    },
    initMapData(value){
        if(value == 'en') {
          this.loadMap = 'google'
        } else {
          this.loadMap = 'kakao'
        }
        this.getEventPoint();
        // if(this.contentsId != null) {
        //   this.getLocation(this.contentsId);
        // }
      },
      onMapCenter() {
        if (this.eventInfo == null) {
          return;
        }
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.lng = this.eventInfo.lng;
          this.lat = this.eventInfo.lat;
          this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }
      },

      playMarkerLngLat(lng,lat,index) {
        this.$refs.map.onSetLngLat(lng,lat);
        if(this.location.length > 0 ) {
          let locationData = this.location[index];
          this.doneData.distance = locationData.distance;
          this.doneData.hspeed = locationData.hspeed;
          this.doneData.vspeed = locationData.vspeed;
          this.doneData.height = locationData.height;
          this.doneData.battery = locationData.battery;
          this.doneData.heartRate = locationData.heartRate;
          this.lng = lng;
          this.lat = lat;
        }
      },
      async getEventPoint() {
        let params = {
          eventId : this.eventId,
          serverTime : null,
        };
        await fetchPointList( params).then((res) => {
          if (res.data.result == 0) {
            this.pointServerTime = res.data.data.serverTime
            this.pointCount = res.data.data.total
            this.pointList = res.data.data.content
            this.pointList.forEach((element) => {
              element.selectId = element.index;
              element.selectType = "Point";
              element.isOwner = false;
              let pointMapMarker = new MapMarkerData();
              element.name = this.$t(getPointTypeName(element.type));
              pointMapMarker.setPointData(element);
              this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
            })
          }
        });
      },
      onTimeUpdate(time, contentsId) { 
        var findIndex = this.getVideoPlayerListIndex(contentsId)
          if(findIndex != -1 && this.saveVideoPlayer[findIndex].locationSize > 0) {
            this.vodMarkerUpdate(this.saveVideoPlayer[findIndex],time); 
          }
        },
      vodMarkerUpdate(saveItem, time){
        //여기에 header값, radar값 변경
        console.log("vodMarkerUpdate")
        var sec = time;
          if(saveItem.location != null && saveItem.location.length > 0) {
            var line = this.$refs.map.createLinePointDataList(saveItem.location);
            var first = line[0];
            first.time =  new Date(first.time);
            
            var isFindIndex = (element) => {
              var firstTime = first.time.getTime() / 1000;
              var filterTime = element.time.getTime() / 1000;
              var findTime = filterTime - firstTime;
              return sec <= findTime;
            };

            var findIndex = line.findIndex(isFindIndex);
            if (findIndex != -1) {
              if (findIndex > line.length) {
                findIndex = line.length;
              }
            } else {
                findIndex = 0;
            }
            saveItem.doneData = this.setDoneData(saveItem.location[findIndex]);
            saveItem.lat = saveItem.location[findIndex].lat;
            saveItem.lng = saveItem.location[findIndex].lng;
            saveItem.azimuth = saveItem.location[findIndex].azimuth;
            saveItem.gimbalDirection = saveItem.location[findIndex].gimbalDirection;
            
            var markerData = new MapMarkerData();
            markerData.setDroneData(saveItem.location[findIndex])
            var mapDataType = MapDataType.ID_DRONE
            markerData.id = saveItem.id
            markerData.name = saveItem.vodObj.deviceName ? saveItem.vodObj.deviceName  : "휴대폰"
            markerData.userName = saveItem.vodObj.recordUserName
            markerData.userDepartmentName = saveItem.vodObj.recordUserDeptName

            markerData.pointType = this.markerColorName[saveItem.playerIndex];
            markerData.playerIndex = saveItem.playerIndex

            this.updateMapMarker(mapDataType,markerData);
          }
      },
      createMapMarker(mapDataType,id,marker,playerIndex){
        var markerData = new MapMarkerData();
        if( marker.location.length > 0)
          markerData.setDroneData(marker.location[0])

        markerData.id = id
        markerData.lat = marker.lat
        markerData.lng = marker.lng
        markerData.name = marker.vodObj.deviceName ? marker.vodObj.deviceName  : "휴대폰"
        markerData.userName = marker.vodObj.recordUserName ? marker.vodObj.recordUserName : " - "
        markerData.userDepartmentName = marker.vodObj.recordUserDeptName
        markerData.mapDataType= mapDataType
        markerData.playerIndex = playerIndex
        markerData.pointType = this.markerColorName[playerIndex];
        if (this.$refs.map.onIsMarker(mapDataType, markerData.id)) {
          this.$refs.map.onModifyMarkerAt(mapDataType, markerData);
          console.log("마커수정")
        }else{
          this.$refs.map.onCreateMarker(mapDataType, markerData);
          console.log("마커생성")

        } 
      },
      updateMapMarker(mapDataType,markerData){
        if (this.$refs.map.onIsMarker(mapDataType, markerData.id)) {
          this.$refs.map.onModifyMarkerAt(mapDataType, markerData);
        }else{
          this.onCreateMarker(mapDataType, markerData);
        }
      },
      createMapLine(mapDataType, id, locationGroup,playerIndex) {
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isShow = true;
      mapLineData.playerIndex = playerIndex
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
  //#endregion

  //#region vod 관련 
    getVodList() {
        fetchVodUrlList(this.eventId).then((res) => {
          this.vodUrl = res.data.data.properties.vodStreamUrl + "/" + res.data.data.properties.application;
          console.log("fetchVodUrlList" , this.vodUrl);
          console.log(this.eventId);
        });

        let params = {
          pageNumber: this.currentPage,
          pageSize: 10,
          eventId: this.eventId,
          deviceType: 'Drone,Mobile',
          keyword: this.keyword,
          keywordType: this.keywordType,
          eventStatus: 'Open',
        };
        console.log("paras ",params);
        if (this.startYmd && this.endYmd) {
          let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
          let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
          params["registeredDateRange.from"] = from;
          params["registeredDateRange.to"] = to;
        }

        fetchVodList(params).then((res) => { 
          console.log(res.data.data.content);
          this.vodList = res.data.data.content;
          // this.$refs.vodList.vodList = res.data.data.content;
          // this.$refs.vodList.total = res.data.data.total;
          this.total = res.data.data.total;
          this.vodList.forEach(item =>{
            item.isChecked= false;
            console.log(item);
          })
        });
      },
   
    isVideoSelected(id,selectValue) { //VideoSelected 되었을 경우 
      return this.vodSelectList.filter(e => e.playerIndex == selectValue && e.id== id).length > 0;    
    },

    isVideoSelectDisabled(selectValue) { //videoDisabled의 경우 
        if(selectValue == undefined) return true
        return this.vodSelectList.filter(e => e.playerIndex == selectValue).length > 0;
    },
    isLiveSelected(id){
      return this.saveVideoPlayer.filter(e => e.id == id && e.type=="Live")
    },

    async vodSaveClick(){
      console.log("vodSaveClick")
      var tempArr = [];
      console.log(this.vodSelectList);
      //선택한녀석 selectted.forEact 돌리기.
      this.vodSelectList.forEach(item => {
        var findInex = this.getVideoPlayerListIndex(item.id)
        this.initDoneData();
        if(findInex == -1){
          tempArr.push(item);
        }else{
          this.saveVideoPlayer[findInex].playerIndex = item.playerIndex;
        }
      })
      this.saveVideoPlayer = this.saveVideoPlayer.filter(item => {
          if(item.type =='Live') return item;
          var findIndex = this.getVodSelectListIndex(item.id);
          if(findIndex != -1) {
            return item;
          }else{
            this.$refs.map.onDeleteMarkerAt(item.mapDataType,item.id);
            this.$refs.map.onDeleteLineAt(item.mapDataType,item.id);
          }
        
      })
      
      for(const item of tempArr){ 
        var result = {id: "", type : "", playerIndex : "", viewSrc : "", location : [], doneData: {},lat : null, lng : null , locationSize : null , vodObj : {} , mapDataType:"", azimuth : null , gimbalDirection : null }
     
        await fetchVodSrc(item.id).then((res) => { //video.js에서 사용할 데이터 
              result.id = item.id;
              result.type = item.type;
              result.playerIndex = item.playerIndex;
              result.viewSrc = this.vodUrl + res.data.data.streamUri 
          });
        
        let params = {
          contentsId: item.id,
        };

        await fetchVodDetail(item.id).then((res) => { 
          if(res.data.result == 0) {
            result.vodObj = res.data.data;
          }
        }); 

        await fetchLocation(params).then((res) => { //kakaomap 및 vod data 호출 
          if (res.data.result == 0) {
            var locationSize = res.data.data.content.length;
            if( this.locationSize == 0) {
              return;
            }
            var locationGroup = res.data.data.content.sort(function (a, b) {
              return a.index - b.index;
            })
            var first = locationGroup[0]
            var lng = undefined;
            var lat = undefined;
            var gimbalDirection = undefined;
            var azimuth = undefined
            if(first != undefined){
              lng = first.lng;
              lat = first.lat
              gimbalDirection = first.gimbalDirection;
              azimuth = first.azimuth;
              first.selectId = item.id
              
              //추후 휴대폰이냐 드론이냐에따라 mapDataType 세팅변경가능. 
            } 
            result.lng = lng
            result.lat = lat 
            result.azimuth = azimuth;
            result.gimbalDirection = gimbalDirection;
            result.locationSize = locationSize;
            result.doneData = first ? this.setDoneData(first) : this.doneData 
            result.mapDataType =  MapDataType.ID_DRONE;
          } 
          result.location = locationGroup;
          // this.createMapMarker(result.mapDataType ,result.id, result, item.playerIndex);
          // this.createMapLine(result.mapDataType, result.id, result.location ,item.playerIndex)
        });
        console.log("result : ",result);

        this.saveVideoPlayer.push(result);
       
      }
      console.log("saveVideoPlayer : ",this.saveVideoPlayer);
       this.saveVideoPlayer.forEach(item =>{
          this.createMapMarker(item.mapDataType ,item.id, item, item.playerIndex);
          this.createMapLine(item.mapDataType, item.id, item.location ,item.playerIndex)
        })
      this.closeModal();

    },
    vodClear(){
      this.vodSelectList = []
      this.vodCheckList =[]
    },
    
    videoSelectChange(event , id) {
        console.log("selectVideo");
        const findIndex = this.getVodCheckListIndex(id)
        if(findIndex != -1) {
          this.vodSelectList[findIndex].playerIndex = event.target.value
        }
      },

    videoCheckChange(event, id){ //checkBox선택시 발생 이벤트
      console.log("videoCheckChange");
      var findIndex = this.getVodCheckListIndex(id)
      if(!event.target.checked){ //선택 해제시 selectbox값 삭제
        var selectIndex = this.getVodSelectListIndex(id);
        this.$delete(this.vodSelectList,selectIndex)
      }
      else if(this.isLengthExceeded) { //check 가능한 수  확인 
        event.target.checked = false;
        this.$delete(this.vodCheckList, findIndex);
        this.$delete(this.vodSelectList, findIndex);
      }else{
        this.createVodSelect(id,null);
      }
    },

    getVodListIndex(id){
        return this.vodList.findIndex(item => {return item.id == id})
    },

    getVodCheckListIndex(id){
      return this.vodCheckList.findIndex(item => {return item.id == id})
    },

    getVodSelectListIndex(id){
      return this.vodSelectList.findIndex(item => {return item.id == id})
    },
    getVideoPlayerListIndex(id){
       return this.saveVideoPlayer.findIndex(item => {return item.id == id})
    },
    getVideoPlayerList(index){

      var result = this.saveVideoPlayer.filter(item =>{
         return (item.playerIndex==index)
      })
      if(result.length > 0)
        console.log("videoPLayerList",result[0])
        return (result.length > 0 ) ? result[0] : undefined
    },
    getLiveListIndex(id){
      return this.liveList.findIndex(item =>{return item.channelId == id}) 
    },
   
    createVodSelect(id, playerIndex){ //처음 check하는 영역 초기값 - 로 잡아줌. 
      var findIndex = this.getVodCheckListIndex(id);
      this.vodSelectList.push({
            id: this.vodCheckList[findIndex].id,
            type: this.vodCheckList[findIndex].type, 
            playerIndex : playerIndex,
          }) 
    },
    async getThumbnail(contentId) {
      let thumb = "";
      await fetchVodThumbnail(contentId).then((res) => {
        thumb = res.data;
        console.log(res)
      });
      
      return thumb;
    },
    
  //#endregion
  
  //#region live 관련 
    async getLiveList() {
      
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        assignedStatus: "Assigned",
        eventId : this.eventId,
      };

      await fetchChannelList(params).then((res) => {
        this.liveTotal = res.data.data.total;
        this.liveList = res.data.data.content;
      });

    },
     async updateMapPosition() {
      if(!this.isLive) return 
      let params = {
        eventId :  this.eventId,
      };
    params
      await fetchChannelList(params).then((res) => {
        let content = res.data.data.content;
        if(content.length > 0) {
          content.forEach(item => {
            var isFindIndex = (data) => {
              return data.id == item.id;
            };
            var findIndex = this.saveVideoPlayer.findIndex(isFindIndex);
            if(findIndex != -1) {
                this.channelToMapMarker(item);
            }
          });
        }
      });
    },
     updateMapLine(){
      if(!this.isLive) return
      var live = this.saveVideoPlayer.filter(item=>{
        if(item.type == 'Live' && item.playerIndex == '0')
          return item
      })
     
      var refId = this.getChannelRefId(live[0]);
      if(refId != null) {
        this.getLocation(refId,live[0])
      }
    },
    getChannelRefId(channel){
      var refId = null;
      
      if(channel.vodObj.deviceType == "Mobile") {
        refId = channel.vodObj.workerId;
      } else {
        refId = channel.vodObj.deviceLogId;
      }
      return refId;
    },
     channelToMapMarker(channelData){
      var isFindIndex = (data) => {
        return data.id == channelData.id;
      };
      var findIndex = this.saveVideoPlayer.findIndex(isFindIndex);
      let mapDataType = MapDataType.ID_DRONE
      let pointMapMarker = new MapMarkerData();
      pointMapMarker.id = channelData.id;
      pointMapMarker.lng = channelData.lng;
      pointMapMarker.lat = channelData.lat;
      pointMapMarker.rotate = channelData.azimuth;
      pointMapMarker.name = findIndex + 1;
      var isMain = false;
      if( this.currentLive.id == channelData.id) {
        isMain = true;
      }
      pointMapMarker.isMain = isMain;
      if(this.$refs.map.onIsMarker(mapDataType,channelData.id ) ) {
        this.$refs.map.onModifyMarkerAt(mapDataType, pointMapMarker);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, pointMapMarker);
      }
    },

    getLocation(refId,liveData){
      liveData
      if(this.isLocationReq == true) {
        return;
      }
      this.isLocationReq = true;

      let mapDataType = MapDataType.ID_DRONE;
      let offset = this.$refs.map.onGetLineSize(mapDataType, refId); //liveData.id를 넣어야하나. live~~값 
      let params = {
        refId: refId,
        eventId: this.eventId,
        offsetIndex: offset,
      };
      fetchLocation(params).then((res) => {
        this.isLocationReq = false;
        if (res.data.result == 0) {
          if (res.data.data.content.length > 0) {
            res.data.data.content.sort(function (a, b) {
              return a.index - b.index;
            })
            //res.data.data.content가 location임.
            liveData.location = res.data.data.content;
            liveData.lat = liveData.location[liveData.location.length-1].lat
            liveData.lng = liveData.location[liveData.location.length-1].lng
            liveData.doneData = this.setDoneData(liveData.location[liveData.location.length-1])
            //각도조절도 하기 liveData.location.gimbalDirection
        
            this.createMapMarker(MapDataType.ID_DRONE,liveData.id,liveData ,0);
            this.createMapLine(MapDataType.ID_DRONE, liveData.id, res.data.data.content,0);
          }
        }
      }).catch((e)=>{
        e;
        this.isLocationReq = false;
      });
    },

    liveCheckChange(event,id){
     
      const checkbox = document.getElementsByName("liveCheckBox");
      
      var saveId = null;
      var check = false;
    console.log("liveCheckChange");
    
    if(this.liveId != null && this.liveId == id) {
        this.$delete(this.checkLiveList , 0);
        saveId = null;
        console.log("1")
        check = true
      }
      if(this.liveId != null && saveId != id) {
        this.$delete(this.checkLiveList , 0)
        saveId = id;
         console.log("2")
      }
      
      if(this.liveId == null) {
          saveId = id;
      }
      
      if(saveId != null) {
        this.liveId = saveId;
        checkbox.forEach(( item )=> {
          item.checked = item.checked && saveId != id;
        })
      }
      check ? this.liveId = null : ''
      
    },
    liveSaveClick(){
      console.log("liveSaveClick")
      this.saveVideoPlayer.forEach( (item,index) => {
          if (item.playerIndex=='0'){
            this.$refs.map.onDeleteMarkerAt(item.mapDataType,item.id);
            this.$refs.map.onDeleteLineAt(item.mapDataType,item.id);
            this.$delete(this.saveVideoPlayer,index);
          }
         
      })
     
      var isLive = this.checkLiveList.filter(item =>{
        return item.type == "Live" 
      }).length > 0 
      
      if(isLive){
        var result = {id: "", type : "", playerIndex : "", viewSrc : "", location : [], doneData: {},lat : null, lng : null , locationSize : null , vodObj : {} , mapDataType:"" }
        var findIndex = this.getLiveListIndex(this.liveId);
        if(findIndex != -1){
          result.id = this.liveList[findIndex].channelId ;
          result.type = "Live";
          result.playerIndex = "0";
          result.lat = this.liveList[findIndex].lat;
          result.lng = this.liveList[findIndex].lng;
          result.doneData = this.setDoneData(this.liveList[findIndex]);
          result.vodObj = this.setVodObj(this.liveList[findIndex]);
          result.viewSrc = getLiveUrl(this.liveList[findIndex]);
          result.mapDataType = MapDataType.ID_DRONE
        }
        this.saveVideoPlayer.push(result);
        this.createMapMarker(MapDataType.ID_DRONE,result.id,result,result.playerIndex);
       
       this.updateMapLine();

      }else{
        console.log("")
      }
      this.closeLiveModal();
    }
  //#endregion
  },
   beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.lineInterval);
    clearInterval(this.positionInterval);
  },
};
</script>
<style>

.content-wrap{width: 100%;display: flex;}

.live-vod{width: 50%;}
.live-vod > .live-box{background: #ffffff;border-radius: 5px;height: 480px;position: relative;margin-bottom: 20px;}
.live-vod > .live-box > span{background: #ff3737;position: absolute;top: 15px;left: 15px;border-radius: 5px;padding: 2px 10px;color: #fff;}
.live-vod > .live-box > span > img{margin-right: 5px;vertical-align: baseline;}

.live-info{display: flex;justify-content: space-between;}
.live-info > .comparison-map{width: 43%;height: 310px;background: #ffffff;border-radius: 5px; position: relative;}
.live-info > .comparison-map > img{display: none;}
.live-info > .live-vod-info{width: 55%;background: #ffffff;border-radius: 5px;padding: 15px;}
.live-info > .live-vod-info > .live-vod-info-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.live-info > .live-vod-info > .live-vod-info-title > h2{color: #040c1c;font-size: 1.4rem;font-weight: bold;margin: 0;}
.live-info > .live-vod-info > .live-vod-info-title > span{background: #ff3737;border-radius: 5px;padding: 2px 10px;color: #fff;font-size: 1.2rem;}
.live-info > .live-vod-info > .live-vod-info-title > span > img{margin-right: 5px;vertical-align: baseline;}
.live-info > .live-vod-info > .live-vod-user-info{background: #f7f7f7;padding: 10px 15px;}
.live-info > .live-vod-info > .live-vod-user-info > .live-user-info-row{display: flex;align-items: center; justify-content: space-between;margin-bottom: 10px;}
.live-info > .live-vod-info > .live-vod-user-info > .live-user-info-row:nth-child(2){margin-bottom: 0;}
.live-info > .live-vod-info > .live-vod-user-info > .live-user-info-row > h4{font-size: 1.2rem;color: #969696;margin: 0;font-weight: 400;}
.live-info > .live-vod-info > .live-vod-user-info > .live-user-info-row > h2{font-size: 1.2rem;color: #040c1c;margin: 0;}
.live-info > .live-vod-info > .live-vod-drone-info{display: flex;align-items: center;justify-content: space-between;margin-top: 15px;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-radar{margin: auto;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-value{ width: 35%;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-value > .live-drone-info-row{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-value > .live-drone-info-row:nth-child(6){margin-bottom: 0;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-value > .live-drone-info-row > h4{color: #969696;font-size: 1.2rem;margin: 0;font-weight: 400;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-value > .live-drone-info-row > h2{margin: 0;}
.live-info > .live-vod-info > .live-vod-drone-info > .live-drone-value > .live-drone-info-row > h2 > span{font-size: 1.5rem;color: #040c1c;margin: 0;}

.live-drone-radar > .drone-radar-img{width: 120px;height: 120px;background: #edf9ff;border-radius: 100px;position: relative;border: 1px solid #aac4ff;}
.live-drone-radar > .drone-radar-img::before{content: '';position: absolute;border: 0.5px solid #b4ccff;width: 100%;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.live-drone-radar > .drone-radar-img::after{content: '';height: 100%;width: 1px;position: absolute;background: #b4ccff;top: 50%;left: 50%;transform: translate(-50%, -50%);}

.live-drone-radar > .drone-radar-img > .radar-img-box{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 100;}
.live-drone-radar > .drone-radar-img > .radar-img-box > .drone-header-img{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 102;}
.live-drone-radar > .drone-radar-img > .radar-img-box > .drone-header-img > .drone-header{position: absolute;width: 45px;transition: 1s all; transform: translate(-50%, -50%);top: 50%;left: 50%;}
.live-drone-radar > .drone-radar-img > .radar-img-box > .camera-radar-img{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 101;}
.live-drone-radar > .drone-radar-img > .radar-img-box > .camera-radar-img > .camera-radar{position: absolute;width: 50px;transition: 1s all; transform: translate(-50%, -100%);top: 50%;left: 50%;}

.drone-coordinate-top{position: absolute;top: -25px;left: 51px;color: #969696;font-size: 1.2rem;}
.drone-coordinate-left{position: absolute;top: 53px;right: -45px;color: #969696;font-size: 1.2rem;}
.drone-coordinate-right{position: absolute;top: 53px;left: -55px;color: #969696;font-size: 1.2rem;}
.drone-coordinate-bottom{position: absolute;bottom: -25px;left: 40px;color: #969696;font-size: 1.2rem;}

.comparison-view{position: relative;margin-left: 20px;width: 49%;display: flex;flex-direction: column;justify-content: space-between;}
.comparison-view > button{position: absolute;top: -37px;right: 0;border: 0;background: #0080FF;color: #fff;font-weight: 300;border-radius: 5px;padding: 2px 15px;}
.comparison-view > .live-search-btn{position: absolute;top: -37px;right: 110px;border: 0;background: #fff;color: #0080ff;border: 1px solid #0080ff;font-weight: 300;border-radius: 5px;padding: 2px 15px;}
.comparison-view > .live-search-btn > span{position: absolute;width: 10px;height: 10px;border-radius: 10px;background: #ff3737;top: -2px;left: -2px;animation: blink 3s infinite ;}
.comparison-view > .live-search-btn > span::before{content: ''; position: absolute;width: 18px;height: 18px;border-radius: 18px;background: #ff5d5d8f;top: -4px;left: -4px;}
@keyframes blink{
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}

.comparison-row{display: flex;align-items: center;justify-content: space-between;}
.comparison-vod{width: 54.5%;background: #ffffff;position: relative;border-radius: 5px;}
.comparison-vod > .vod-mark{background: #1e1f28c7;color: #fff;padding: 3px 15px;border-radius: 5px;position: absolute;top: 10px;left: 10px;font-size: 1.2rem;}
.comparison-vod > .vod-mark > img{margin-right: 5px;}
.comparison-vod > .vod-data{background: #1e1f28c7;color: #fff;padding: 3px 15px;border-radius: 5px;position: absolute;top: 10px;right: 10px;font-size: 1.2rem;}
.comparison-vod-info{width: 44.5%;background: #ffffff;padding: 15px;border-radius: 5px;}
.comparison-vod-info > .vod-info-title{border-bottom: 1px solid #dddddd;margin-bottom: 5px;display: flex;align-items: center;justify-content: space-between;}
.comparison-vod-info > .vod-info-title > h2{font-size: 1.4rem;color: #040c1c;margin-bottom: 5px;font-weight: 600;}
.comparison-vod-info > .vod-info-title > h2 > span{width: 10px;height: 10px;display: inline-block;background: #fff;border-radius: 10px;margin-right: 5px;}
.comparison-vod-info > .vod-info-title > h2 > span.blue{background: #2B79EE;}
.comparison-vod-info > .vod-info-title > h2 > span.orange{background: #FF7F33;}
.comparison-vod-info > .vod-info-title > h2 > span.purple{background: #9E30FF;}
.comparison-vod-info > .vod-info-title > h4{font-size: 1.2rem;color: #fff;margin-bottom: 5px;font-weight: 300;}

.vod-drone-value-info{display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px;}
.vod-drone-value-info > .vod-radar{ width: 40%;margin: auto; padding-left: 25px;}
.vod-drone-value-info > .vod-radar > .drone-radar-img{width: 75px;height: 75px;background: #edf9ff;border-radius: 100px;position: relative;border: 1px solid #aac4ff;}
.vod-drone-value-info > .vod-radar > .drone-radar-img::before{content: '';position: absolute;border: 0.5px solid #b4ccff;width: 100%;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.vod-drone-value-info > .vod-radar > .drone-radar-img::after{content: '';height: 100%;width: 1px;position: absolute;background: #b4ccff;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .radar-img-box{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 100;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .radar-img-box > .drone-header-img{position: absolute;top: 50%;left: 50%;transition: 1s all;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 102;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .radar-img-box > .drone-header-img > .drone-header{position: absolute;width: 25px;transition: 1s all;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .radar-img-box > .camera-radar-img{position: absolute;top: 50%;left: 50%;transition: 1s all;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 101;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .radar-img-box > .camera-radar-img > .camera-radar{position: absolute;width: 32px;transition: 1s all;transform: translate(-50%, -100%);top: 50%;left: 50%;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .drone-coordinate-top{position: absolute;top: -22px;left: 27px;color: #969696;font-size: 1.2rem;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .drone-coordinate-left{position: absolute;top: 30px;right: -40px;color: #969696;font-size: 1.2rem;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .drone-coordinate-right{position: absolute;top: 30px;left: -50px;color: #969696;font-size: 1.2rem;}
.vod-drone-value-info > .vod-radar > .drone-radar-img > .drone-coordinate-bottom{position: absolute;bottom: -20px;left: 20px;color: #969696;font-size: 1.2rem;}

.vod-drone-value{ width: 43%;}
.vod-drone-info-row{display: flex;align-items: center;justify-content: space-between;margin-bottom: 7px;}
.vod-drone-info-row:nth-child(6){margin-bottom: 0;}
.vod-drone-info-row > h4{color: #969696;font-size: 1.2rem;margin: 0;font-weight: 400;}
.vod-drone-info-row > h2{font-size: 1.2rem;color: #040c1c;margin: 0;}
.vod-drone-info-row > h2 > span{font-size: 1.2rem;color: #040c1c;margin: 0;}

.vod-user-info{background: #f7f7f7;padding: 5px 10px;}
.vod-user-info-row{display: flex;align-items: center;justify-content: space-between;margin-bottom: 8px;}
.vod-user-info-row:nth-child(2){margin-bottom: 0px;}
.vod-user-info-row > h4{font-size: 1.2rem;color: #969696;margin: 0;font-weight: 400;}
.vod-user-info-row > h2{font-size: 1.2rem;color: #040c1c;margin: 0;}
.vod-user-info-row > h2 > span{font-size: 1.2rem;color: #040c1c;margin: 0;}
/* .vjs-tech{object-fit: cover;} */
/* 모달 스타일 */
.vod-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
}

.vod-modal-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 1050px;
}
.modal-content-header{display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px;border-bottom: 1px solid #ddd;padding-bottom: 15px;}
.modal-content-header > h2{color: #05101c;font-size: 1.6rem;margin: 0;}
.modal-content-header > h2 > span{padding: 4px 10px;background: #eee;border-radius: 5px;margin-left: 10px;font-size: 1.2rem;color: #969696;}
.modal-content-header > button{border: 0;}
.modal-content-header > button > img{width: 15px;opacity: 0.5;}

.modal-content-body{margin-bottom: 20px;}
.modal-input{display: flex;align-items: center;margin-bottom: 15px;}
.modal-search{margin-right: 10px;display: flex;align-items: center;}
.modal-search > span{color: #969696;background: #eee;padding: 8px 15px;border-radius: 5px;margin-right: 10px;}
.modal-search > select{background: #ffffff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;width: 110px;border: 1px solid #ddd;height: 32px;padding: 5px 25px 5px 12px;border-radius: 5px;font-size: 1.2rem;color: #fff;}
.modal-search > select:focus-visible{outline: 1px solid #2B79EE;}
.modal-search > select > option{background: #ffffff;color:#969696;}
.modal-search > input{margin: 0;background: #ffffff;border: 0;margin-left: 5px;color: #000; border-radius: 5px;border: 1px solid #ddd;}
.modal-search > input:hover{border: 1px solid #ddd;}
.modal-search > input:focus-visible{outline: 1px solid #2B79EE;}

.modal-datepicker{display: flex;align-items: center;margin-right: 10px;}
.modal-datepicker > span{color: #969696;background: #eee;padding: 8px 15px;border-radius: 5px;margin-right: 10px;}
.modal-datepicker > .datepicker-input > input{margin: 0;border: 0;background: #ffffff;border-radius: 3px;color: #000; border-radius: 5px;border: 1px solid #ddd;}


.buttonWrap{margin: 0;}
.buttonWrap > button{padding: 2px 27px;border: 0;font-weight: 300;color: #fff;border-radius: 5px;}
.buttonWrap > button:nth-child(1){background: #0080FF;margin-right: 8px;}
.buttonWrap > button:nth-child(2){background: #fff;color: #969696;border: 1px solid #ddd;}

.modal-table{max-height: 400px;height: 400px;overflow-y: scroll;border-bottom: 1px solid #ddd;border-top: 3px solid #7a9aff;}
.modal-table::-webkit-scrollbar-thumb{background-color: #dddddd;border-radius: 4px;}
.modal-table::-webkit-scrollbar-track{border-radius: 2px;background: #f7f7f7;border: 1px solid #f7f7f7;}
.modal-table > table > thead > tr{position: sticky;top: -1px; z-index: 10;}
.modal-table > table > thead > tr > th{background: #efefef;color: #969696;text-align: center;border-bottom: 1px solid #ddd;}
.modal-table > table > tbody > tr:hover{background: #f7f7f7;}
.modal-table > table > tbody > tr > td{border-bottom: 1px solid #ddd;text-align: center;color: #05101c;padding: 5px 0;overflow: revert;text-overflow: revert;}
.modal-table > table > tbody > tr > td > select{width: 100px;border-radius: 5px;border: 0;color:#3d321c;  background: #fff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;border: 1px solid #ddd;}
.modal-table > table > tbody > tr > td > select:focus-visible{outline: 1px solid #2B79EE;}
.modal-table > table > tbody > tr > td > select > option{background: #fff; color: #1d1d1d;}
.modal-table > table > tbody > tr > td > select:disabled{background: #eee;}
.modal-table > table > tbody > tr > td > select > option:disabled{background: #cccccc; color: #8b8b8b;}

.modal-content-footer{text-align: center;margin: 30px 0 10px 0;}
.modal-content-footer > button{border: 0;padding: 5px 40px;border-radius: 5px;margin: 0 5px;height: 36px;font-weight: 300;color: #fff;}
.modal-content-footer > button:nth-child(1){background: #0080FF;color: #fff;}
.modal-content-footer > button:nth-child(2){background: #ffffff;color:#969696;border: 1px solid #ddd;}
.modal-content-footer > button:nth-child(3){background: #ffffff;color:#969696;border: 1px solid #ddd;}

.vod-none{background: #000000;border-radius: 5px;position: relative;margin-bottom: 20px;padding-top: 56.25%;}
.vod-play-on{border-radius: 5px;position: relative;margin-bottom: 20px;}
.vod-none > img{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -70%);width: 80px;}
.vod-none > h4{position: absolute;top: 60%;left: 50%;transform: translate(-50%, -50%);color: #fff;font-size: 1.2rem;font-weight: 400;}

.comparison-vod-none{width: 54.5%;height: 100%;background: #000000;position: relative;border-radius: 5px;}
.comparison-vod-none > img{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -70%);width: 60px;}
.comparison-vod-none > h4{position: absolute;top: 65%;left: 50%;transform: translate(-50%, -50%);color: #fff;font-size: 1.2rem;font-weight: 400;}

.live-modal {position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;justify-content: center;align-items: center;z-index: 1003;}
.live-modal-content {background-color: #282B35;padding: 20px;border-radius: 8px;width: 1020px;}

.live-modal-content > .modal-content-header{display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px;border-bottom: 1px solid #454545;padding-bottom: 15px;}
.live-modal-content > .modal-content-header > h2{color: #fff;font-size: 1.6rem;margin: 0;}
.live-modal-content > .modal-content-header > button{border: 0;}
.live-modal-content > .modal-content-header > button > img{width: 15px;opacity: 0.5;}

.live-modal-content > .modal-content-body > .modal-table{max-height: 400px;height: 400px;overflow-y: scroll;border-bottom: 1px solid #454545;border-top: 3px solid #7a9aff;}
.live-modal-content > .modal-content-body > .modal-table::-webkit-scrollbar-thumb{background-color: #12141d;border-radius: 4px;}
.live-modal-content > .modal-content-body > .modal-table::-webkit-scrollbar-track{border-radius: 2px;background: #383f4c;border: 1px solid #383f4c;}
.live-modal-content > .modal-content-body > .modal-table > table > thead > tr{position: sticky;top: -1px;}
.live-modal-content > .modal-content-body > .modal-table > table > thead > tr > th{background: #171b21;color: #969696;text-align: center;border-bottom: 1px solid #454545;}
.live-modal-content > .modal-content-body > .modal-table > table > tbody > tr:hover{background: #20232f;}
.live-modal-content > .modal-content-body > .modal-table > table > tbody > tr > td{border-bottom: 1px solid #454545;text-align: center;color: #fff;}
.live-modal-content > .modal-content-body > .modal-table > table > tbody > tr > td > select{width: 100px;border-radius: 5px;border: 0;color:#fff;  background: #444c5b url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;}
.live-modal-content > .modal-content-body > .modal-table > table > tbody > tr > td > select > option{background: #444c5b;}


.live-modal-content > .modal-content-footer{text-align: center;margin: 30px 0 10px 0;}
.live-modal-content > .modal-content-footer > button{border: 0;padding: 5px 40px;border-radius: 5px;margin: 0 5px;height: 36px;font-weight: 300;color: #fff;}
.live-modal-content > .modal-content-footer > button:nth-child(1){background: #3ea2f7;}
.live-modal-content > .modal-content-footer > button:nth-child(2){background: #444c5b;}

.video-player .video-js{width: 100%;padding-top: 56.25%;}
.vjs-control-bar{border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}
.datepicker-input{display: flex;align-items: center;}
.datepicker-input > .vdp-datepicker input{margin-bottom: 0;background: #ffffff;border: 0;color: #000;border-radius: 5px;border: 1px solid #ddd;}
.datepicker-input > .vdp-datepicker input:nth-child(1){margin-right: 5px;}
.datepicker-input > .vdp-datepicker input:focus-visible{outline: 1px solid #2B79EE;}

.el-pagination {white-space: nowrap;padding: 2px 5px;color: #969696;font-weight: 400;}
.el-pagination button:disabled {color: #969696;background-color: #ffffff;cursor: not-allowed;}
.el-dialog, .el-pager li {background: #ffffff;-webkit-box-sizing: border-box;}
.el-pagination .btn-next, .el-pagination .btn-prev {background: center center no-repeat #ffffff;background-size: 16px;cursor: pointer;margin: 0;color: #969696;}

.preview-list{width: 40px;position: relative;}
.preview-list > .preview-img{height: 40px;width: 80px;background: #eee;border-radius: 5px;}
.preview-list > .preview-img > img{width: 100%;height: 100%;object-fit: cover;}
.preview-list > .preview-img-big{width: 250px;height: 150px;background: #eee;position: absolute;z-index: 10;top: 40px;left: 50px;display: none;box-shadow: 1px 1px 5px #bbbbbb;}
.preview-list > .preview-img-big > img{width: 100%;height: 100%;}
.preview-list > .preview-img:hover ~ .preview-img-big{display: block;}

.modal-table > table > tbody tr:nth-child(8) > .preview-list > .preview-img-big{top: -100px;left: 50px;}
.modal-table > table > tbody tr:nth-child(9) > .preview-list > .preview-img-big{top: -100px;left: 50px;}
.modal-table > table > tbody tr:nth-child(10) > .preview-list > .preview-img-big{top: -100px;left: 50px;}
</style>